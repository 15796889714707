import { AspectRatio, Box } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import { useEffect, useRef, useState } from "react";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture} roomPicture
 * @property {number} [maxContainerWidth]
 * @property {number} [ratio]
 */
/**
 * @param {Props} props
 */
export function RoomPictureLazy({
  roomPicture,
  maxContainerWidth = 350,
  ratio = 4 / 3,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef(/** @type {HTMLDivElement | null} */ (null));

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.01 },
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return (
    <Box ref={ref} flexShrink={0} w="full">
      <AspectRatio ratio={ratio}>
        {isVisible ? (
          <PampaImage
            image={roomPicture}
            mode="crop"
            format="default"
            maxContainerWidth={maxContainerWidth}
            alt=""
          />
        ) : (
          <Box />
        )}
      </AspectRatio>
    </Box>
  );
}
