import { HStack, Stack, Text } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { getSearchHotelRank } from "../../../helpers/search/getSearchHotelRank";

export const HotelReviewSummary = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").HotelReviewInfos} reviewInfos
   * @property {boolean} [withLabel]
   */
  /**
   * @param {Props} props
   */
  function HotelReviewSummary({ reviewInfos, withLabel = true }) {
    const intl = useIntl();

    const rank = useMemo(() => {
      return getSearchHotelRank({ reviewInfos });
    }, [reviewInfos]);

    if (reviewInfos.count === 0) {
      return null;
    }

    if (rank === null) {
      return null;
    }

    return (
      <HStack spacing="8px">
        <Stack spacing="0">
          {rank.label !== null && withLabel && (
            <Text
              variant="text-sm"
              textAlign="right"
              color="brandPrimary.600"
              whiteSpace="nowrap">
              {intl.formatMessage(rank.label)}
            </Text>
          )}

          <Text
            variant="text-xs-link"
            textAlign="right"
            color="gray.500"
            whiteSpace="nowrap">
            <FormattedMessage
              defaultMessage="{count} {count, plural, =0 {avis} one {avis} other {avis}}"
              values={{
                count: intl.formatNumber(reviewInfos.count),
              }}
            />
          </Text>
        </Stack>

        {reviewInfos.average && (
          <Text
            display="inline-block"
            variant="button-md"
            backgroundColor={rank.color}
            color="#fff"
            borderRadius="10px"
            textAlign="center"
            whiteSpace="nowrap"
            minW="34px"
            p="6px"
            flexShrink={0}>
            <FormattedNumber
              value={reviewInfos.average}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </Text>
        )}
      </HStack>
    );
  },
);
